<template>

  <form
    v-if="data"
    class="AccountForm"
    @submit.prevent="onSubmit">

    <div class="TopPart">
      <div class="SectionWrap">
        <SectionHeadline :text="mixWB('INFORMATION')" />

        <InputField
          formGroupName="account-form"
          :value="data.name"
          :label="mixWB('ACCOUNT_NAME')"
          isRequired
          name="name"
          :disabled="!canEdit"
          @on-update="onUpdate" />

        <InputField
          formGroupName="account-form"
          :value="data.address"
          :label="mixWB('ADDRESS')"
          isRequired
          name="address"
          :disabled="!canEdit"
          @on-update="onUpdate" />

        <InputField
          formGroupName="account-form"
          :value="data.postalCode"
          :label="mixWB('POSTAL_CODE')"
          isRequired
          name="postalCode"
          :disabled="!canEdit"
          @on-update="onUpdate" />

        <InputField
          formGroupName="account-form"
          :value="data.city"
          :label="mixWB('CITY')"
          isRequired
          name="city"
          :disabled="!canEdit"
          @on-update="onUpdate" />

        <br/>

        <SectionHeadline :text="mixWB('CONTACT_PERSON')" />

        <InputField
          formGroupName="account-form"
          :value="data.contactPerson.name"
          :label="mixWB('NAME')"
          isRequired
          name="contactPerson.name"
          :disabled="!canEdit"
          @on-update="onUpdate" />

        <InputField
          formGroupName="account-form"
          :value="data.contactPerson.phoneNumber"
          :label="mixWB('PHONE')"
          isRequired
          name="contactPerson.phoneNumber"
          :disabled="!canEdit"
          @on-update="onUpdate" />

        <InputField
          formGroupName="account-form"
          :value="data.contactPerson.email"
          :label="mixWB('EMAIL')"
          isRequired
          name="contactPerson.email"
          :disabled="!canEdit"
          @on-update="onUpdate" />
      </div>

      <div class="SectionWrap">
        <SectionHeadline :text="mixWB('LABORATORY')" />

        <Select
          name="primaryLabID"
          :label="mixWB('STANDARD_LABORATORY')"
          :selectedID="data.primaryLabID"
          :options="labOptions"
          @change="onUpdate" />

        <Select
          name="analysisDeliveryTimeOptionID"
          :label="mixWB('STANDARD_DELIVERY_TIME_FOR_ANALYSES')"
          :selectedID="data.analysisDeliveryTimeOptionID"
          :options="analysisDeliveryTimeOptions"
          @change="onUpdate" />

        <InputField
          formGroupName="account-form"
          :value="data.euroFinsNumber"
          :label="mixWB('OFFER_NUMBER') + ' (' + mixWB('LAB_DK_EUROFINS_TITLE') + ')'"
          :isRequired="data.primaryLabID === 'euroFins'"
          name="euroFinsNumber"
          :disabled="!canEdit"
          @on-update="onUpdate" />

        <InputField
          formGroupName="account-form"
          :value="data.hojvangNumber"
          :label="mixWB('OFFER_NUMBER') + ' (' + mixWB('LAB_DK_HOJVANG_TITLE') + ')'"
          :isRequired="data.primaryLabID === 'hojvang'"
          name="hojvangNumber"
          :disabled="!canEdit"
          @on-update="onUpdate" />

        <InputField
          formGroupName="account-form"
          :value="data.analysisReportEmail"
          :label="mixWB('EMAIL_FOR_RECEIVING_ANALYSIS_REPORTS')"
          name="analysisReportEmail"
          :disabled="!canEdit"
          @on-update="onUpdate" />
      </div>
    </div>

    <Button
      v-if="canEdit"
      :isLoading="isSaving"
      :text="mixWB('SAVE')"
      type="submit" />

    <Accordion
      :title="mixWB('STAT_TABLE_TITLE')"
      :bodyComponent="Stat"
      :bodyComponentProps="{
        stats: currentAccount,
      }"
      :padding="'none'"
      v-if="currentAccount.overallStats" />
  </form>
</template>

<script>
import InputField from '@/components/FormElements/InputField.vue'
import Button from '@/components/Buttons/Button.vue'
import { mapGetters } from 'vuex'
import { set } from 'lodash-es'
import EventBus from '@/EventBus'
import Accordion from '@/components/Accordion.vue'
import Stat from '@/components/Stat.vue'
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'
import Select from '@/components/FormElements/Select.vue'

export default {
  name: 'Account',
  data() {
    return {
      isSaving: false,
      data: {
        name: '',
        address: '',
        postalCode: '',
        city: '',
        contactPerson: {
          name: '',
          phoneNumber: '',
          email: '',
        },
        primaryLabID: '',
        analysisDeliveryTimeOptionID: '',
        euroFinsNumber: '',
        hojvangNumber: '',
        analysisReportEmail: '',
      },
      Stat,
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'currentAccount',
      'activeLaboratoriesAsArray',
      'analysisDeliveryTimeOptionsAsArray',
    ]),
    canEdit() {
      return this.currentUser.isSuperUser || this.currentUser.isAdmin
    },
    labOptions() {
      return this.activeLaboratoriesAsArray.reduce((prev, lab) => {
        if (!lab.isActive && this.data.primaryLabID !== lab.id) {
          return prev
        }

        prev.push({
          id: lab.id,
          text: this.mixWB(lab.translation),
        })
        return prev
      }, [])
    },
    analysisDeliveryTimeOptions() {
      return this.analysisDeliveryTimeOptionsAsArray.map((item) => ({
        id: item.id,
        text: item.days === 1
          ? this.mixWB('1_DAY_X_O_CLOCK', [item.hours.toString()])
          : this.mixWB('X_DAYS_X_O_CLOCK', [item.days.toString(), item.hours.toString()]),
      }))
    },
  },
  methods: {
    onUpdate({ name, value }) {
      set(this.data, name, value)
    },
    onSubmit() {
      this.isSaving = true
      EventBus.$emit('validate-field', 'account-form')

      // Check all required fields
      if (!this.data.name
        || !this.data.address
        || !this.data.postalCode
        || !this.data.city
        || !this.data.contactPerson.name
        || !this.data.contactPerson.phoneNumber
        || !this.data.contactPerson.email
        || !this.data.primaryLabID
        || !this.data.analysisDeliveryTimeOptionID
        || (this.data.analysisDeliveryTimeOptionID === 'euroFins' && !this.data.euroFinsNumber)
        || (this.data.analysisDeliveryTimeOptionID === 'hojvang' && !this.data.hojvangNumber)
      ) {
        this.isSaving = false

        return
      }

      this.$store.dispatch('updateAccount', { data: this.data, id: this.currentAccount.id })
        .then(() => {
          this.isSaving = false
        })
    },
  },
  components: {
    InputField,
    Button,
    Accordion,
    SectionHeadline,
    Select,
  },
  created() {
    Object.keys(this.data).forEach((key) => {
      if (typeof this.currentAccount[key] !== 'undefined') {
        this.data[key] = typeof this.currentAccount[key] === 'object'
          ? { ...this.currentAccount[key] }
          : this.currentAccount[key].toString()
      }
    })
  },
}
</script>

<style lang="stylus" scoped>
  .AccountForm
    padding-top 10px

  .TopPart
    display flex
    justify-content space-between
    .SectionWrap
      width calc(50% - 15px)
      margin-bottom 30px
    +below($tablet)
      display block
      .SectionWrap
        width 100%

  >>> .Accordion
    margin-top 30px
    .Inner
      overflow auto

</style>
